export default {
'en-us':
{
    message:
    {
        error:                      'Error',
        error_sorry:                'We are sorry :(',
        error_title:                'Sorry, something went wrong...',
        error_slide:                'Error... Cannot load this slide...',
        error_temporarily:          'Service is temporarily unavailable...',
        error_presentation:         'Document is not available yet...',
        error_video:                'Sorry, this video is currently unavailable...',
        error_topic:                'Unspecified topic',
        error_submit_failed:        'Submission failed...',
        error_form_invalid:         'Please fix the highlighted errors...',
        error_nobookings:           'No bookings found :(',
        success_form_saved:         'Your changes have been saved...',
        operation_completed:        'Operation completed',
        operation_failed:           'Operation failed',
        warning:                    'Warning',
        notice:                     'Notice',
        loading:                    'Loading...',
        ooops:                      'Ooops...',
        autodetect:                 'Autodetect',
        action_no:                  'No',
        action_yes:                 'Yes',
        action_yes_sure:            "Yes, I'm sure",
        action_ok:                  'Ok',
        action_send:                'Send',
        action_submit:              'Submit',
        action_download:            'Download',
        action_close:               'Close',
        action_setpass:             'Change Password',
        action_showmore:            'Load more...',
        action_install:             'INSTALL APPLICATION',

        title_confirmedbookings:    'Confirmed Bookings',
        title_schedulecancel:       'Schedule / Cancel',
        title_bookcancel:           'Book / Cancel',
        title_comments:             'Comments',
        title_timetable:            'Timetable',
        title_topics:               'Topics',
        title_alltopics:            'All Topics',
        title_myclasses:            'My Classes',
        title_upclasses:            'Upcoming Classes',
        title_confirmedclasses:     'Confirmed Classes',
        title_teachers:             'Teachers',
        title_myteachers:           'My Teachers',
        title_payments:             'Payments',
        title_mystudents:           'My Students',
        title_students:             'Students',
        title_myprofile:            'My Profile',
        title_logout:               'Logout',
        title_classroom:            'Classroom',
        title_chatwithus:           'Chat with us',
        title_instructions:         'Instructions',

        login:                      'Login',
        login_teacher:              'Teacher Login',
        login_student:              'Student Login',
        login_username:             'Email',
        login_username_hint:        'Please type your email',
        login_password:             'Password',
        login_password_hint:        'Please type your password',
        login_continuewith:         '┄┄┄┄┄┄┄┄┄ OR ┄┄┄┄┄┄┄┄┄',
        login_signup_info:          "Don't have an account?",
        login_signup_button:        'Sign-up',
        login_forgot_button:        'Forgot password?',
        login_error_social_title:   'Social login failed',
        login_error_social_text:    'Sorry, an unexpected error occurred... Please try again.',
        login_error_social_exists:  'Email address is already registered...',

        password_forgot_title:      'Forgot your password?',
        password_forgot_text:       "Don't worry. Resetting your password is easy, just tell us the email address you registered with us.",
        password_forgot_success:    'Password has been changed!',
        password_forgot_error:      'This password reset link is invalid',

        signup_title_student:       'Student Registration',
        signup_title_teacher:       'Teacher Registration',
        signup_title_confirm:       '',
        signup_email:               'Email',
        signup_email_hint:          'Please type your email address',
        signup_password:            'password',
        signup_password_hint:       'Please type your password',
        signup_passcheck:           'Re-type password',
        signup_passcheck_hint:      'Please re-type your password',
        signup_firstname:           'First Name',
        signup_firstname_hint:      'Please type your first name',
        signup_lastname:            'Last Name',
        signup_lastname_hint:       'Please type your last name',
        signup_submit:              'Register',
        signup_cancel:              'Cancel',
        signup_success_title:       'Signup successful!',
        signup_success_text:        'To activate your account, check your email and confirm the registration. You will shortly receive the following email with the APP GUIDE and further instructions. Thank you.',
        signup_confirm_success:     'Success... Your registration is complete.',
        signup_confirm_error:       'This activation link is expired or have already been used...',

        timetable_today:            'Today',
        timetable_tomorrow:         'Tomorrow',
        timetable_thisweek:         'This week',
        timetable_nextweek:         'Next week',
        timetable_class_noschedule: 'NO SCHEDULE FOUND',
        timetable_class_free:       'FREE',
        timetable_class_booked:     'BOOKED',
        timetable_class_confirmed:  'CONFIRMED',
        timetable_class_completed:  'COMPLETED',

        booking_availablecredits:   'Prepaid classes:',
        booking_availableshort:     'Prepaid:',
        booking_credits:            'CLASSES',
        booking_credit:             'CLASS',
        booking_cancel_title:       'Cancel booking',
        booking_cancel_text:        'Are you sure you want to cancel your booking?',
        booking_cancel_impossible:  'This class cannot be canceled.',
        booking_failed_title:       'Booking failed',
        booking_failed_text:        'We are sorry, the booking operation could not be completed.',
        booking_failed_conflict:    'Unfortunately, this teacher has been already booked by someone else. Please choose different time.',

        booking_failed_credits:     "Sorry, you don't have enough prepaid classes to complete the booking...",
        booking_teacher_choose:     'Select your teacher',
        booking_teacher_none:       'Unfortunately, there are no available teachers left for this time range.',
        booking_confirm_text:       'Are you sure you want to confirm this booking?',
        booking_comment_warning:    'Sorry! This class has been already commented.',
        booking_rating_text:        'Tap a star to rate your teacher',
        booking_topic_select:       'Choose the topic',
        booking_noslots:            'No teachers are offering their time slots today...',
        booking_timezone:           'All times are in your local time zone and follow your phone time settings.',
        booking_now:                'Book now!',

        booking_emergency_s_title:  'Emergency cancel',
        booking_emergency_s_text:   'We are sorry that you must cancel your class! We truly understand that you are busy now. You are about to cancel your class in less than 12 hours and lose your credit. Are you sure you want to cancel your booking?',
        booking_emergency_t_title:  'Emergency cancel',
        booking_emergency_t_text:   'Are you sure you want to cancel this booking?',

        schedule_failed_title:      'Operation failed',
        schedule_failed_text:       'We are sorry, the operation could not be completed.',
        schedule_booked_title:      'Notice',
        schedule_booked_text:       'Cannot reschedule, this class is already booked by student {name}.',
        schedule_noclasses_title:   'We are sorry :(',
        schedule_noclasses_text:    'There are no classes scheduled for this date',

        schedule_save:              'Confirm Changes',
        schedule_online:            'ONLINE',
        schedule_online_go:         'GO ONLINE',
        schedule_online_cancel:     'Cancel',
        schedule_online_title:      'Notice',
        schedule_online_text:       'Cannot reschedule, this class is part of currently running online mode.',
        schedule_online_dialog:     'ONLINE MODE',
        schedule_online_note1:      'Students can book any section before off-line time.',
        schedule_online_note2:      "The class can't be canceled once it was booked.",
        schedule_online_note3:      'Please make sure your app is running and you are waiting.',
        schedule_online_warning:    '*Any default will cause this feature being turned off at once.',
        schedule_online_select:     'Please select online duration:',

        schedule_offline:           'OFFLINE',
        schedule_offline_go:        'GO OFFLINE',
        schedule_offline_title:     'Cancel online mode',
        schedule_offline_text:      'Are you sure you want to cancel online mode?',

        topics_number_0:            'No topics',
        topics_number_1:            '1 Topic',
        topics_number_2:            'Topics',
        topics_warning:             'We are sorry, you cannot enter the topics now. Purchase one of the packages to unlock your app functionality again. Thank you for your understanding.',

        class_error_completed:      'This class has already finished...',
        class_error_notfound:       'We could not find classroom data. The booking has been canceled or rescheduled.',
        class_error_media:          'Unable to access your camera and/or microphone...',
        class_leave_title:          'Warning',
        class_leave_text:           'Are you sure you want to leave connected class session?',
        class_action_joinclass:     'Join Class',
        class_action_comment:       'Comment',
        class_action_rate:          'Rate Class',
        class_action_preview:       'Preview',
        class_action_cancel:        'Cancel',
        class_action_emergency:     'Emergency',
        class_action_unmute_mic:    'Unmute Microphone',
        class_action_mute_mic:      'Mute Microphone',
        class_action_unmute_cam:    'Unmute Camera',
        class_action_mute_cam:      'Mute Camera',
        class_action_disconnect:    'Disconnect',
        class_action_connect:       'Connect',
        class_action_reconnect:     'Reconnect',
        class_action_testcamera:    'Camera Test',
        class_action_show_chat:     'Show Chat',
        class_action_hide_chat:     'Hide Chat',
        class_action_share_screen:  'Share Screen',
        class_action_unshare_screen:'Stop Share Screen',
        class_state_startsin:       'CLASS STARTS IN',
        class_state_connected:      'CONNECTED',
        class_state_disconnected:   'DISCONNECTED',
        class_state_finished:       'FINISHED',
        class_state_live:           'LIVE',
        class_state_error:          'We are sorry, an error has occurred...',
        class_state_notconnected:   'Not connected',
        class_state_accessing:      'Accessing your camera...',
        class_state_connecting:     'Connecting...',
        class_state_failed:         'Connection failed!',
        class_state_reconnection:   'Participant disconnected... Waiting for reconnection...',

        comments_allcomments:       'All Comments',
        comments_mycomments:        'My Comments',
        comments_notfound:          'No comments found :(',
        comments_new_submit:        'Submit',
        comments_new_cancel:        'Cancel',

        profile_save:               'Save',
        profile_gender:             'Gender',
        profile_gender_male:        'Male',
        profile_gender_female:      'Female',
        profile_age:                'Age',
        profile_first_name:         'First name',
        profile_last_name:          'Last name',
        profile_email:              'Email address',
        profile_phone:              'Phone number',
        profile_country:            'Country',
        profile_currency:           'Preferred Currency',
        profile_iban:               'IBAN',
        profile_address:            'Address',
        profile_address2:           'Address 2',
        profile_zip:                'Postal code',
        profile_city:               'City',
        profile_avatar_picture:     'New picture',
        profile_password:           'Password',
        profile_passcheck:          'Re-type password',
        profile_description:        'Self-Description',
        profile_preferred_topics:   'Preferred topics',
        profile_intro_url:          'Video-introduction (Youtube Url)',
        profile_notify:             'Notifications',
        profile_notify_none:        'Disabled',
        profile_notify_push:        'Push',
        profile_notify_mail:        'Email',
        profile_notify_test:        'Notification Test',

        buycredits_title:           'Select your package',
        buycredits_nooptions:       'No pricing options found...',
        buycredits_button:          'Buy more classes',
        buycredits_button_normal:   'Buy classes',
        buycredits_button_short:    'Buy',
        buycredits_history:         'Payments history',
        buycredits_history_short:   'History',

        teacher_filter:             'Teacher',
        teacher_select:             'Select a teacher',
        teachers_empty:             'No teachers found :(',
        student_filter:             'Filter by student',
        student_select:             'Select a student',
        students_empty:             'No students found :(',

        details_title_teacher:      'Teacher Details',
        details_title_student:      'Student Details',
        details_teacher_info:       "TEACHER'S SELF-DESCRIPTION",
        details_preferred_topics:   'PREFERRED TOPICS',
        details_available_classes:  'AVAILABLE CLASSES',
        details_available_notfound: 'No planned classes found...',
        details_classes_history:    'CLASSES HISTORY',
        details_classes_notfound:   'No classes found...',

        field_required:             'This field is required',
        field_invalid:              'Your entry is not valid',
        field_email_invalid:        'This email address is not valid',
        field_email_exists:         'This email address is already registered',
        field_too_short:            'Your entry is too short',
        field_too_long:             'Your entry is too long',
        field_password_match:       'Passwords must match',
        field_video_invalid:        'Your entry is not a valid youtube link',

        classes_upcoming:           'Upcoming',
        classes_finished:           'Finished',
        classes_all:                'All',

        payments_notfound:          'No purchased packages found :(',

        class_state_ns:             'Waiting for the class to start...',
        class_state_wa:             'Waiting for both participants...',
        class_state_wt:             'Waiting for the teacher...',
        class_state_ws:             'Waiting for the student...',
        class_state_wtl:            "You've been waiting for your teacher for 5 minutes. Take a screen-shot, send it to us, and you can leave the classroom. No worries, you will get your credit back.",
        class_state_wsl:            "You've been waiting for your student for 15 minutes. Take a screen-shot, send it to us, and you can leave the classroom. No worries, you will get paid.",
        class_state_cp:             'Class in progress...',
        class_state_ce:             'The class is almost over...',
        class_state_cc:             'The class is over'
    }
}
};
